import { Controller } from '@hotwired/stimulus';

import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

export default class extends Controller {
  connect() {
    this.baseEventsUrl = this.element.dataset.baseEventsUrl;
    this.calendars = JSON.parse(this.element.dataset.calendarsJson);
    this.calendar = new Calendar(this.element, {
      firstDay: 1,
      plugins: [dayGridPlugin, listPlugin, timeGridPlugin, bootstrap5Plugin],
      themeSystem: 'bootstrap5',
      initialView: 'dayGridMonth',
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,listWeek',
      },
      eventSources: this.calendars.map((calendar) => {
        const eventsUrl = `${
          this.baseEventsUrl
        }?calendar_id=${encodeURIComponent(calendar.id)}`;
        return {
          url: eventsUrl,
          color: calendar.color,
        };
      }),
      eventClick: this.eventClick.bind(this),
    });
    this.calendar.render();
  }

  eventClick(event) {
    if (event.event.url) {
      event.jsEvent.preventDefault();
      window.open(event.event.url, '_blank');
    }
  }
}
